import { getContent } from "@/.server/get-content.mjs";
import { List, ListItem } from "@/ui/atoms/list/list";
import { Title } from "@/ui/atoms/title/title";
import type { LoaderFunctionArgs } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";

export async function loader(args: LoaderFunctionArgs) {
  const posts = (await getContent({ type: "blog" })).slice(0, 5);
  const projects = (await getContent({ type: "projects" })).slice(0, 5);

  return { posts, projects };
}

export default function IndexView() {
  const { posts, projects } = useLoaderData<typeof loader>();
  return (
    <div className="content">
      <section className="space-y-8">
        <section>
          <Title>Rokas Muningis // Software Engineer</Title>
          <span>I share my thoughts, love and hate about, but not limited to Typescript and React.</span>
        </section>
        <section>
        <List title="Latest blog posts:">
          {posts.map((post) => (
            <ListItem key={post.slug}>
              <Link className="underline text-main-300" to={`/blog/${post.slug}`}>
                {post.title}
              </Link>
            </ListItem>
          ))}
        </List>
        </section>
        <section>
        <List title="Projects:">
          {projects.map((project) => (
            <ListItem key={project.slug}>
              <Link className="underline text-main-300" to={`/projects/${project.slug}`}>
                {project.title}
              </Link>
            </ListItem>
          ))}
        </List>
        </section>
        <section>
        <List title="Upcoming talks">
          <ListItem>None at the moment :(</ListItem>
        </List>
        </section>
        <section>
        <List title="Find me on:">
          <ListItem>
            <a className="underline text-main-300" href="https://github.com/muningis" target="_blank" rel="noreferrer">
              github
            </a>
          </ListItem>
          <ListItem>
            <a
              className="underline text-main-300"
              href="https://www.linkedin.com/in/rokas-muningis/"
              target="_blank"
              rel="noreferrer">
              linkedin
            </a>
          </ListItem>
          <ListItem>
            <a className="underline text-main-300" href="https://twitter.com/muningis" target="_blank" rel="noreferrer">
              twitter
            </a>
          </ListItem>
        </List>
        </section>
      </section>
    </div>
  );
}
